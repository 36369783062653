@import "main.css";

.informations-list > div { color: #000000 !important;
}

h1 { 
  display: flex; /* Active Flexbox pour centrer */
  justify-content: center; /* Centrage horizontal */
  align-items: center; /* Centrage vertical */
  height: 25vh; /* Hauteur du conteneur */
  margin: 0; /* Supprime les marges par défaut */
  text-align: center; /* Centre le texte */
  width: 100%; /* Assure que le titre prend toute la largeur */
}

.bandeau > div {
width:100% !important;
}